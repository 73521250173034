<template>
  <validation-observer ref="ticketForm">
    <b-form>
      <b-card>
        <div class="d-flex">
          <feather-icon icon="SettingsIcon" size="19" class="text-primary" />
          <h4 class="mb-0 ml-50 text-primary">{{ $t("Genel") }}</h4>
        </div>
        <b-row class="mt-1">
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Departman')" label-for="departmentTuru">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Departman"
              >
                <v-select
                  v-model="ticket.departmentTuru"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="departmentTuruOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="departmentTuru"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Öncelik')" label-for="ticketOncelikTuru">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Öncelik"
              >
                <v-select
                  v-model="ticket.ticketOncelikTuru"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="ticketOncelikTuruOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="ticketOncelikTuru"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Konu')" label-for="sKonu">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Konu"
              >
                <b-form-input
                  type="text"
                  id="sKonu"
                  v-model="ticket.sKonu"
                  :placeholder="$t('Konu')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <label for="file">{{ $t('Dosya') }}</label>
            <b-form-file v-model="file" id="file" ref="file-input" accept="image/*" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Açıklama')" label-for="sAciklama">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Açıklama"
              >
                <b-form-textarea
                  id="textarea-sAciklama"
                  :placeholder="$t('Açıklama')"
                  v-model="ticket.sAciklama"
                  rows="4"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <b-form-group
              :label="$t('Uzak Destek Bilgileri')"
              label-for="sUzakDestekBilgisi"
            >
              <b-form-textarea
                id="textarea-sUzakDestekBilgisi"
                :placeholder="$t('Uzak Destek Bilgileri')"
                v-model="ticket.sUzakDestekBilgisi"
                rows="4"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <div class="d-flex">
          <feather-icon icon="UserIcon" size="19" class="text-primary" />
          <h4 class="mb-0 ml-50 text-primary">{{ $t("Gönderen") }}</h4>
        </div>

        <b-row class="mt-1">
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('İlgili Kişi')" label-for="sIlgiliKisi">
              <b-input-group>
                <b-form-input
                  id="sIlgiliKisi"
                  :placeholder="$t('İlgili Kişi')"
                  v-model="ticket.sIlgiliKisi"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Telefon')" label-for="sTelefon">
              <cleave
                id="sTelefon"
                v-model="ticket.sTelefon"
                placeholder="(999) 999-9999"
                class="form-control"
                :raw="false"
                :options="options.phone"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('E-Posta')" label-for="sEPosta">
              <validation-provider
                #default="{ errors }"
                rules="email"
                name="E-Posta"
              >
                <b-input-group>
                  <b-form-input
                    id="sEPosta"
                    :placeholder="$t('E-Posta')"
                    v-model="ticket.sEPosta"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <save-button
          :showSaveAndClose="false"
          :onClickSave="save"
        />
      </b-card>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email,
} from '@validations'
// Alert
import AlertService from '@/common/alert.service'
import StaticOptions from '@/common/options/StaticOptions';

import SaveButton from '@/components/SaveButton.vue';
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import Cleave from 'vue-cleave-component'

export default {
  components: { SaveButton, Cleave, ValidationProvider, ValidationObserver },
  data() {
    return {
      required,
      email,
      ticket: {
        id: 0,
        sIlgiliKisi: '',
        sEPosta: '',
        sTelefon: '',
        departmentTuru: 1,
        ticketOncelikTuru: 2,
        sKonu: '',
        sAciklama: '',
        sDosyaUrl: '',
        sUzakDestekBilgisi: '',
        IlgiliPersonel_Id: 0,
      },
      file: null,
      departmentTuruOptions: StaticOptions.departmentTuruOptions,
      ticketOncelikTuruOptions: StaticOptions.ticketOncelikTuruOptions,
      options: {
        phone: {
          blocks: [0, 3, 0, 3, 4],
          delimiter: ' ',
          delimiters: ['(', ')', ' ', '-', '-'],
          numericOnly: true,
          uppercase: false,
          lowercase: false,
        },
      },
      form: new FormData(),
    }
  },

  methods: {
    fetchMusteriBilgi() {
      this.$store.dispatch('profileSettings/fetchProfile')
        .then(response => {
          const { musteri, sTelefon, sCepTelefon, sEMail, sAd, sSoyad } = response.result;
          this.ticket.sIlgiliKisi = `${sAd} ${sSoyad}`;
          this.ticket.sTelefon = sCepTelefon || sTelefon;
          this.ticket.sEPosta = sEMail;
        })
    },
    save() {
      this.$refs.ticketForm.validate().then(success => {
        if (success) {
          for (const prop in this.ticket) {
            if (typeof this.ticket[prop] === 'string') {
              this.form.set(prop, this.ticket[prop] || '')
            } else {
              this.form.set(prop, this.ticket[prop])
            }
          }
          this.form.set('file', this.file);
          this.$store.dispatch('ticketCustomer/saveTicket', this.form)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                })
                this.$router.push({ name: 'customer-support-detail', params: { detailId: response.result } })
              } else {
                AlertService.error(this, response.message)
              }
            })
        }
      })
    },
  },

  mounted() {
    this.fetchMusteriBilgi();
  },
}
</script>

<style>
</style>
